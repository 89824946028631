import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { 
    persistReducer, 
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

// Import reducers
import appReducer from './actions/appSlice';

const allReducers = combineReducers({
    app: appReducer,
});

const rootReducer = (state, action) => {  
    if(action.type === "app/resetApp"){
      storage.removeItem("persist:root")
      state = undefined;
    } 
    return allReducers(state, action);
};
  
const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools: (process.env.NODE_ENV === 'development') ? true : false
});
  
export const persistor = persistStore(store);