import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar"
// import BaseFooter from "./BaseFooter";
import { Layout } from "antd";

const { Content } = Layout;

const PublicLayout = () => {
  return (
    <Layout className="flex flex-col min-h-screen w-full font-display">
      <TopBar />
      <Content className="flex justify-center bg-white w-full px-40">
        <Outlet />
      </Content>
      {/* <BaseFooter /> */}
    </Layout>
  )
}

export default PublicLayout;