const items = [
    {
      label: 'About Me',
      key: 'about_me',
    //   icon: <MailOutlined />,
    },
    {
      label: 'Portfolio',
      key: 'portfolio',
    //   icon: <AppstoreOutlined />,
    //   disabled: true,
    },
    {
        label: 'Contact Me',
        key: 'contact_me',
      //   icon: <AppstoreOutlined />,
      //   disabled: true,
      },
];

export default items