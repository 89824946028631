import React from 'react'
import { Button, Result } from 'antd';
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Result
            // className="revert-tailwind"
            status="404"
            title="404"
            subTitle="Nothing to see here."
            extra={
                <Button type="primary" onClick={() => navigate(-1)}>Take me back please...</Button>
            }
        />
    )
}

export default NotFound