import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import {
  ConfigProvider
} from 'antd';

import PublicLayout from './layout/PublicLayout';
import FrontPage from './pages/FrontPage';
import NotFound from './pages/NotFound';

function App() {

  const theme = useSelector(state => state.app.theme);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Oswald",
          fontSize: 18,
          colorPrimary: "#0608c3",
          colorInfo: "#0608c3",
          borderRadius: 0
        },
        components: {
          Button: {
            algorithm: true
          }
        }
      }}
    >
    <Router>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<FrontPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
    </ConfigProvider>
  );
}

export default App;
