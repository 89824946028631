import React from 'react'

import CustomCardWithTitle from '../components/CustomCardWithTitle'

const FrontPage = () => {
  return (
    <div className='flex flex-col w-full'>
      <CustomCardWithTitle title={"Portfolio"}>
        <p className='text-base font-semibold text-black'>
          MY PORTFOLIO LIST
        </p>
      </CustomCardWithTitle>
    </div>
  )
}

export default FrontPage