import { 
    createSlice, 
    // createAsyncThunk 
} from "@reduxjs/toolkit";

const initialState = {
    theme: 0 // 0 = light mode, 1 = dark mode
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
      setTheme: (state, action) => {
        state.theme = action.payload;
      },  
      resetApp: () => initialState
    },
    extraReducers: (builder) => {
    //   builder
    //     .addCase(loginUser.pending, (state, action) => {
    //       state.isLoadingBtnLogin = true;
    //     })
    //     .addCase(loginUser.fulfilled, (state, action) => {
    //       state.isLoadingBtnLogin = false;
    //     })
    //     .addCase(loginUser.rejected, (state, action) => {
    //       state.isLoadingBtnLogin = false;
    //     }) 
    },
});

  export const {
    setTheme,
    resetApp
  } = appSlice.actions;
  
  export default appSlice.reducer;