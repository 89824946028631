import React from 'react'
import { 
    ConfigProvider, 
    Layout, 
    Button, 
    Dropdown, 
    Menu
} from 'antd';

import MenuItems from '../components/TopBar/MenuItems';

import { ReactComponent as ExpandIcon } from "../assets/svg/BurgerMenu.svg";

const { Header } = Layout;

const TopBar = () => {
  return (
    <Header className='flex flex-row items-center justify-between px-40 bg-[#0608c3] sticky top-0 z-10'>
        <div>
            <p className="text-lg text-[#FFFFFF] text-[16px]">ANADHARMA</p>
        </div>
        <div>
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        horizontalItemHoverColor: "#FFFFFF",
                        horizontalItemSelectedColor: "#FFFFFF",
                        itemHoverColor: "#FFFFFF",
                        itemColor: "#FFFFFF",
                        activeBarHeight: 4,
                        itemBg: "#0608c3",
                        fontSize: 16,
                        popupBg: "#0608c3",
                    }
                }
            }}
        >
            <Menu 
                // onClick={onClick} 
                // selectedKeys={[current]} 
                mode="horizontal"
                items={MenuItems} 
                className="flex-auto min-w-0 lg:w-96"
                overflowedIndicator={<ExpandIcon className="w-6 h-6"/>}
            />
        </ConfigProvider>
        </div>
    </Header>
  )
}

export default TopBar