import React from 'react'
import {
    Card,
} from 'antd';

const CustomCardWithTitle = ({
    title,
    children
}) => {
  return (
    <div className='flex flex-col items-center justify-center'>
        <p className="text-xl font-bold text-white uppercase ">
            {title}
        </p>
        <Card className="shadow-md w-full">
            {children}
        </Card>
    </div>
  )
}

export default CustomCardWithTitle